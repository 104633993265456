import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
//import Img from 'gatsby-image'
import { Text } from 'theme-ui'
import { Layout, Stack, Main } from '@layout'
import PageTitle from '@components/PageTitle'
import Divider from '@components/Divider'
//import Section from '@components/Section'
import Seo from '@widgets/Seo'

/**
 * Shadow me to add your own content
 */

// const styles = {
//   imageWrapper: {
//     borderRadius: `default`,
//     overflow: `hidden`,
//     position: `relative`
//   },
//   button: {
//     display: [`none`, `block`],
//     position: `absolute`,
//     bottom: 4,
//     right: 4
//   },
//   grid: {
//     flexWrap: [`wrap`, `wrap`, `nowrap`],
//     ' > div + div': {
//       ml: [0, 0, 5]
//     }
//   },
//   column: {
//     flex: `auto`,
//     flexBasis: [`full`, `full`, `1/2`]
//   }
// }

export default props => {
  const data = useStaticQuery(privacyQuery)

  //const { photo } = data
  //const { fluid } = (photo && photo.childImageSharp) || {}

  return (
    <Layout {...props}>
      <Seo title='Privacy' />
      <Divider />
      <Stack>
        <Main>
          <PageTitle
            header='Privacy Policy'
            subheader=''
          />
          <Text variant='p'>
            This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of Your information when You use this website.<br/>
            By using this website, You agree to the collection and use of information in accordance with this Privacy Policy.
          </Text>
          <Text><i>Last updated: January 21, 2021</i></Text>
          <h2>Types of information we may collect from you</h2>
          <Text variant='p'>
            Swift-Arm.com does not collect any personal information from its users.
          </Text>
          <Divider/>
          <h2>Information our website automatically collects about you</h2>
          <Text variant='p'>
          With regard to each of your visits to our website we may automatically collect information including the following:
            <ul>
              <li>Technical information, including a truncated and anonymised version of your Internet protocol (IP) address, browser type and version, operating system and platform;</li>
              <li>Information about your visit, including what pages you visit, how long you are on the site, how you got to the site (including date and time); page response times, length of visit, what you click on, documents downloaded and download errors.</li>
            </ul>
          </Text>
          <Divider/>
          <h2>How we may use the information we automatically collect about you</h2>
          <Text variant='p'>
            <ul>
              <li>To administer our site including troubleshooting and statistical purposes;</li>
              <li>To improve our site to ensure that content is presented in the most effective manner for you and for your computer;</li>
              <li>Security and debugging as part of our efforts to keep our site safe and secure.</li>
            </ul>
          This information is collected anonymously and is not linked to information that identifies you as an individual.
          </Text>
          <Divider/>
          <h2>Third party links</h2>
          <Text variant='p'>
            Our site may, from time to time, contain links to and from third party websites.
            If you follow a link to any of these websites, please note that these websites have their own privacy policies and that we do not accept any responsibility or liability for these policies.
            Please check these policies before you submit any personal data to these websites.
          </Text>
          <Divider/>
          <h2>Changes to our privacy policy</h2>
          <Text variant='p'>
            Any changes we may make to our privacy policy in the future will be posted on this page. Please check back frequently to see any updates or changes to our privacy policy.
          </Text>
        </Main>
      </Stack>
    </Layout>
  )
}

const privacyQuery = graphql`
  query PrivacyQuery {
    photo: file(absolutePath: { regex: "/about.(jpeg|jpg|gif|png)/" }) {
      childImageSharp {
        fluid(maxWidth: 1140, maxHeight: 500, cropFocus: NORTH) {
          ...GatsbyImageSharpFluid_noBase64
          width: presentationWidth
          height: presentationWidth
        }
      }
    }
  }
`
